// Cada uno de los items que aparecen en el buscador cuando el usuario introduce un texto
.locationSearcherBoxItem {
  font-weight: 300;
  color: $light-grey;
  padding-left: 0.56rem;
  line-height: 3;

  &:hover {
    background-color: $purple-main;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: $white;
  }
}

.locationSearcherBoxItem:not(:last-child){
  border-bottom: 1px solid $extra-light-grey;
}