.loadingWithMessage__container{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 1rem;

    p{
        color: $light-grey;
    }
}