// Colores base principales
$dark-grey: #343434;
$light-grey: #4a4a4a;
$extra-light-grey: #ccc;

$extra-white:#f8f9fa;
$white: #fff;

// Colores principales
$purple-main: #564EE1;
$green-dark: #417505;
$green-light: #7ed321;
$yellow-light: #cbb731;

$red-main: #D0021B;

// Fuente - Tipografía
$font-family-main: "Nunito", sans-serif;
i{
    margin-right:0.3rem;
}
