// Contenidos de información de la app o de la web reutilizables

// Bloque: Imagen en la izquierda y título y párrafo de la sección descritivo en la derecha
//---------

.contentImageWithFeatures {
  width: 100%;

  .contentImageWithFeatures__container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    background-color: $white;

    @include for-desktop-big {
      flex-direction: row;
      width: 65%;
    }

    
  }
  .contentImageWithFeatures__image_box {
    flex: 1 1;
    text-align: center;
    img {
      max-width: 80%;
      height: auto;
      margin: 0 auto;
    }

    @include for-tablet {      
      width: 65%;
      margin: 0 auto;
    }
  }

  // Contenido de texto descriptivo (título y párrafo)
  .contentImageWithFeatures__content {
    display: flex;
    flex: 2 0;
    flex-direction: column;
    padding: 0.56rem 1rem 0.56rem 1rem;
    h3 {
      @include title-content;
    }
    p {
      @include paragraph-content;
    }
  }

  // Bloque de características
  .contentImageWithFeatures__features {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include for-phone{
      flex-direction: column;
      align-items: center;
    }
  }

  // Pequeño bloque con título e imágen (feature)*/
  .blockItemWithtitleAndImage__container {
    @include block-image-with-small-p;
  }

  // Para la posición de un elelmento en la derecha o izquierda y ahorrar código
  .fistElementOrder {
    order: 1;
  }
  .secondElementOrder {
    order: 2;
  }
}

//------FIN BLOQUE IMAGEN + TITULO + FEATURES EN DERECHA---------/


//------BLOQUE TÍTULO + SUBTITULO + IMAGE---------/

.contentBlockTitleSubtitleWithImageItem__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  background-color: $white;

  @include for-desktop-big {
    width: 65%;
  }

  img {
    max-width: 80%;
    height: auto;
    margin: 0 auto;
  }

  h3 {
    @include title-content;
    text-align: center;
  }
  p {
    @include paragraph-content;
    text-align: center;
    margin-bottom: 2rem;
  }
}
