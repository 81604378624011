
// Contenedor de cada item de las features
.featureBlock__container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    align-items: center;
    padding: 0.56rem;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex:1;
    background-color: $white;
  
  @include for-tablet{
      max-width: calc(33%);
  }
  
    @include for-desktop{
      max-width: calc(33% - 4rem);
    }
  
    @include for-phone {
      max-width: 100%;
    }
    
  
    h2 {
      margin: 0;
      padding: 0;
      color: $purple-main;
      text-align: center;
      font-size: 1.2rem;
    }
    
    .featureBlock__feature-description{
      margin-top: 0.56rem;
      p {
          margin: 0;
          padding: 0;
          text-align: center;
          color: $light-grey;    
          font-size: 1rem;
        }
    }
  
    .featureBlock__feature-img {
      margin-bottom: 0.56rem;
      i {
        color: $extra-light-grey;
      }
    }
}