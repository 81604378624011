// Contenedor global de la página web
.container__global{
background-color: $extra-white;
}
// Contenedor de páginas miscelánea (Privacy & Condiciones de uso...)
.pageMisc__container{
    .pageMisc__content{
        width: 90%;
        margin: 0 auto;
        background-color: $white;
        padding: 0.56rem 1rem 0.56rem 1rem;

        @include for-desktop-big {
            width: 65%;
          }

    }

    h1, h2, h3{
        color: $dark-grey;
        margin-top: 0;
        padding-top: 1rem;
    }
    p{
        color: $light-grey;
        line-height: 2;
        text-align: justify;
    }
}