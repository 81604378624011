.listPrice__container{
    width: 90%;
    margin: 0 auto;
    padding-bottom: 3rem;
    background: $white;

    @include for-desktop-big{
        width: 65%;
    }

    // Botón de "Filtros"
    .listPrice__sort_button{
        @include button-white;
        
    }
    
    // Panel de ordenación y filtrado de datos
    .listPrice__sortFilter_container{
        background-color: $white;
        display: none;
        border: 1px solid $extra-light-grey;
        margin-bottom: 1rem;
    }

    .listPrice__header{
        background-color: $purple-main;
        padding: 9px;
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;       

        p{
            margin:0;
            color: $white;
            font-size: 1rem;
            display: inline-block;
            span{
                font-size: 1.2rem;
            }
        }
    }

    .listPrice__body{
        border: 1px solid #ccc;
        min-height: 25vh;        
        height: fit-content;
    }
}
