// Contenedor del listPriceItem
.listPriceItem__container {
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.56rem;
  
  // Caja contenedora con la imagen
    .listPriceItem__gasStation-info-withImage-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.56rem;
    flex: 1;
  }

  // Caja contenedora de información de la gasolinera
  .listPriceItem__gasStation-info-container {
    display: flex;
    //align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;

    @include for-desktop {
      flex-direction: row;
    }

    // Contenido del nombre e información adicional de la estación (Nombre, Dirección, Horario)
    .listPriceItem__gasStation-info {
      flex: 1;
      //margin: 0.56rem 0.56rem 0 0.56rem;
      margin-left: 0.25rem;

      h4 {
        color: $light-grey;
        font-size: 1.2rem;
        font-weight: 700;
        margin: 0;
      }
      p {
        color: $light-grey;
        font-size: 0.9rem !important;
        margin: 0;
        margin-bottom: 0.15rem;
      }

      i {
        color: $purple-main;
      }
    }
  }

  // Bloque informativo de los precios de la estación
  .listPriceItem__gasStation-price-container {
    display: flex;
    justify-content: space-around;
    flex: 1;
    margin-top: 0.56rem;

    @include for-desktop {
      justify-content: space-between;
    }

    // Cada uno de los bloques del precio del carburante
    .listPriceItem__gasStation-price-item {
      margin: 0 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      // Título del carburante
      h4 {
        color: $light-grey;
        font-size: 1.2rem;
        font-weight: 300;
        margin: 0;

        @include for-phone{
          font-size: 0.9rem;
        }

        span {
          font-weight: 700;
        }

        span.gasolina95 {
          color: $green-dark;
        }
        span.gasolina98 {
          color: $green-light;
        }
        span.diesel {
          color: $yellow-light;
        }
      }

      p {
        font-size: 1.2rem;
        font-weight: 700;
        margin: 0;
        color: #4a4a4a;

        @include for-phone{
          font-size: 0.8rem;
        }
      }
    }
  }
}




// Contenedor del listPriceItem
.listPriceItem__container {
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.56rem;
  
  // Caja contenedora con la imagen
    .listPriceItem__gasStation-info-withImage-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.56rem;
    flex: 1;
  }

  // Caja contenedora de información de la gasolinera
  .listPriceItem__gasStation-info-container {
    display: flex;
    //align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;

    @include for-desktop {
      flex-direction: row;
    }

    // Contenido del nombre e información adicional de la estación (Nombre, Dirección, Horario)
    .listPriceItem__gasStation-info {
      flex: 1;
      //margin: 0.56rem 0.56rem 0 0.56rem;

      h4 {
        color: $light-grey;
        font-size: 1.2rem;
        font-weight: 700;
        margin: 0;

        @include for-phone{
          font-size: 0.9rem;
        }
      }

      p {
        color: $light-grey;
        font-size: 0.8rem;
        margin: 0;
        margin-bottom: 0.15rem;
      }

      i {
        color: $purple-main;
      }
    }
  }

  // Bloque informativo de los precios de la estación
  .listPriceItem__gasStation-price-container {
    display: flex;
    justify-content: space-around;
    flex: 1;
    margin-top: 0.56rem;

    @include for-desktop {
      justify-content: space-between;
    }

    // Cada uno de los bloques del precio del carburante
    .listPriceItem__gasStation-price-item {
      margin: 0 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      // Título del carburante
      h4 {
        color: $light-grey;
        font-size: 1.2rem;
        font-weight: 300;
        margin: 0;

        @include for-phone{
          font-size: 0.8rem;
        }

        span {
          font-weight: 700;

          @include for-phone{
            font-size: 0.8rem;
          }
        }

        span.gasolina95 {
          color: $green-dark;
        }
        span.gasolina98 {
          color: $green-light;
        }
        span.diesel {
          color: $yellow-light;
        }
      }

      p {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0;
        color: $light-grey;

        @include for-phone{
          font-size: 1rem;
        }
        
        span.eLitro {
          font-size: 0.8rem;
          font-weight: 300;
          margin: 0;
        }

        span.noPrice{
          color: $red-main !important;
          font-weight: 300;
          font-size: 1rem;
          @include for-phone{
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

