.locationSearcherBox__container {
  h4 {
    margin: 0;
    padding: 0.56rem;
    font-size: 1.2rem;
    color: $white;
    background-color: $purple-main;
  }

  position: absolute;
  border: 1px solid #ccc;
  background-color: $white;
  top: 7.65rem;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 40vh;
  height: auto;
  overflow: auto;

  @include for-phone {
    top: 13.5rem;
  }
  @include for-tablet {
    top: 9.5rem;
  }
}
