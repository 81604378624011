// Mixings para dispositivos móviles
//------------------------------------

//Ejemplo de uso
//------------
/*
.header-title {  
    font-size: 2rem;  
  
    @include for-phone {    
       font-size: 1rem; 
    }
 }
*/

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@mixin for-phone {
  @media (max-width: 481px) {
    @content;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@mixin for-tablet {
  @media (min-width: 481px) and (max-width: 1024px) {
    @content;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1199px
*/
@mixin for-desktop {
  //@media (min-width: 1025px) and (max-width: 1199px) {
  @media (min-width: 1025px) {
    @content;
  }
}

/* 
  ##Device = Desktops
  ##Screen = 1200px to higher resolution desktops
*/
@mixin for-desktop-big {
  @media (min-width: 1200px) {
    @content;
  }
}

// Mixings botones
//------------------------------------

@mixin button-white {
  padding: 0.5rem;
  background-color: $white;
  border-radius: 10px;
  border: 1px solid #979797;
  display: inline-block;
  font-size: 1rem;
  color: $light-grey;

  &:hover {
    color: $purple-main;
    cursor: pointer;
  }

  i {
    color: $purple-main;
  }
}

// Títulos y textos para contenido duplicado
//------------------------------------

@mixin title-content {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: $purple-main;
  margin-top: 0.8rem;
}

@mixin paragraph-content {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: $light-grey;
  margin: 0.56rem 0;
  line-height: 1.6;
  text-align: justify;
}

// Bloques de información
//------------------------------------

// Bloque con font-awesome y un pequeño párrafo descriptivo
@mixin block-image-with-small-p {
  border: 1px solid $extra-light-grey;
  padding: 0.56rem;
  max-width: 15rem;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 -3px 10px 3px rgba(0, 0, 0, 0.2);

  @include for-phone{
    margin-bottom:2rem;      
  }

  &:not(:first-child) {
    margin-left: 2rem;
    @include for-phone{
      margin-left:0rem;      
    }
  }

  i {
    color: $purple-main;
  }

  p {
    font-size: 1rem;
    text-align: center;
    margin-top: 0.56rem;
  }
}
