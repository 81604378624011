.featuresListBlock__main-container {
  padding: 1rem;
  background-image: url("/img/banner-header-map-web.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.featuresListBlock__container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @include for-phone {
    flex-direction: column;
  }

  @include for-desktop{
    width:70%;
  }
}
