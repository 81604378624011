.headerLocationSearch__container{
    width: 100% !important;
    margin: 0.5rem auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem 1rem;
    background: $white;

    h1,h2{
        color: $dark-grey;
    }
    .headerLocationSearch__title{
        font-size: 2.25rem; // 36px
        margin-top: 0rem;
        margin-bottom: 0.5rem;
        font-weight: 700;
    }

    .headerLocationSearch__subtitle{
        font-size: 1.5rem; // 24px
        margin: 0;
        font-weight: 300;
    }
}