.locationSearcher__container {
  width: 80%;
  margin: 0 auto;
  position: relative;
  padding: 1rem;
  background: $white;
  border: 1px solid $extra-light-grey;
  box-shadow: 0 -3px 10px 3px rgba(0, 0, 0, 0.2);

  @include for-desktop-big{
    width: 65%;
}

  // Cabecera del buscador - Contiene un título
  .locationSearcher__container_header{
    h2{
      color: $purple-main;
      margin: 0;
      margin-bottom:0.56rem;
      padding: 0;
      text-align: center;
      text-align: center;
      font-size: 1.5rem;

    }
  }


  // Contenedor del input type y del botón de lupa
  .locationSearcher__container_input {
    position: relative;

    // Input type text de búsqueda de la localidad/ciudad
    .form-control {
      display: block;
      width: 100%;
      padding: 0.75rem 2.5rem;
      font-size: 1rem;
      line-height: 1.5;
      color: $light-grey;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s;

      &:focus {
        outline: none;
      }
      // Efecto de focus para iluminar la lupa
      &:focus + i {
        color: $purple-main;
        transition: color 0.5s ease;
      }
    }

    i {
      position: absolute;
      left: 0;
      top: 8px;
      padding: 9px;
      color: $extra-light-grey;
    }

    .locationSearcher__loading-icon{
      position: absolute;
      right:15px;
      top:12px;
    }
  }
}




