// Contenedor del paginador de elementos
.pagination__container{
    background-color: $white;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .paginationItem__page{
        padding:0.6rem 1rem;
        border: 1px solid $extra-light-grey;
        border-radius: 10px;
        color: $light-grey;
        margin-left: 0.56rem;
        margin-bottom: 0.56rem;
        cursor: pointer;

        &:hover{
            background-color: $purple-main;
            color: $white;
        }
    }

    .active{
        background-color: $purple-main;
        color: $white !important;
    }
}

.pagination__footer{
    background-color: $purple-main;
    padding: 9px;
    margin-top: 0.5rem;
    display:flex;
    justify-content: flex-end;

    p{
        margin: 0;
        color: #fff;
        font-size: 1rem;
    }

    i{
        color: $white;
    }

}



