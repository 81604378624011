// Configugraciones globales
* {
  font-family: $font-family-main;
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  height: 100%vh;
  width: 100%vw;
}

.pointer {
  cursor: pointer;
}

// Headers

// Estilos propios - Quick styles
//--------------------------------
//Fonts
.font-heavy {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-regular {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

// Visible / No visible

.visible{
  visibility: visible;
}

.not-visible{
  visibility: hidden;
}

