.listPriceItemEmpty__container{
    margin: 0 auto;
    padding: 1.5rem; 
    margin-bottom: 1rem;   
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;


    @include for-tablet{
        flex-direction: row;
        justify-content: center;   
    }

    @include for-desktop{
        flex-direction: row;        
    }

    svg{
        flex:1;
    }

    .listPriceItemEmpty__container-info{
        color: $light-grey;
        flex: 1;
        flex-wrap: wrap;
    }

    .listPriceItemEmpty__container-title{
        margin:0;
        margin-top: 0.56rem;
        padding:0;
        color: $purple-main !important;
    }

    .listPriceItemEmpty__container-description{
        text-align: justify;
        margin:0;
        padding-top: 0.56rem;
    }
}