fieldset {
    border: none;
    label{    
    font-size: 14px;
    font-weight: bold;    
    line-height: 20px;
    display: block;
    margin-bottom: 10px;
    color: $light-grey;
    }
    

    @include for-tablet{
        display: inline;
    }
    @include for-desktop{
        display: inline;
    }
}

.form-btn{
    display: inline-block;
    padding: 10px 16px;
    cursor: pointer;
    border: none;
    text-decoration: none;
}

.form-btn.outlined.btn-dropdown-link {   
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: $light-grey;
    border-radius: 8px;
    position: relative;
    height: 40px;
    border: 1px solid #ccc;
    min-width: 14rem;
}

.btn-dropdown{
    position: relative;
}

//Flechita indicadora de "más elementos"
.btn-dropdown .btn-dropdown-caret{
    position: absolute;
    right: 15px;
    top: 15px;
    width: 10px;
    height: 10px;
    margin: 0;
}

.btn-dropdown .btn-dropdown-link {
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 35px;
}

div.btn-dropdown-options {    
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.05);   
    box-shadow: 0px 3px 5px rgba(0,0,0,0.04);
    background: #fff;
    left: 0;
    min-width: 180px;
    position: absolute;
    top: calc(100% + 8px);
    z-index: 1;
    display: none;
}

div.btn-dropdown-options ul {
    list-style: none;
    padding: 8px 0;
}

div.btn-dropdown-options ul li{
    display: block;
    padding: 8px 15px;
    font-size: 13px;
    color: $light-grey;
    text-decoration: none;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    span{
        margin-left: 0.56rem;
    }
    
    &:hover{
        background-color: lighten($color: $extra-light-grey, $amount: 10) ;
        cursor: pointer;
    }
}

// Controlar la visualización del panel de ordenación/filtrado
// .close{
//     visibility: visible !important;
//     transition: visibility 0.1s ease;
// }

// .close{
//     visibility: hidden !important;
//     transition: visibility 0.1s ease;
// }
