
// Caja de opciones de ordenación de precios
.locationSearcher__container__sort{
    border: 1px solid #ccc;
    padding: 0.56rem;
    margin: 1rem 0 1rem 0;

    h3.locationSearcher__sort_title{
        margin:0;
        padding: 0;
        color: $dark-grey;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 0.56rem;

        i {
            color: $purple-main;
        }
    }

    .locationSearcher__container__sort_options{
        
        display: flex;
        justify-content: flex-start;
        font-size: 0.8rem;
        font-weight: 300;

        >div{
            margin-right: 0.60rem;
            label{
                margin-left: 0.5rem;
                color: $light-grey;
            }
         }
    }  
}