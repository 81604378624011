.navbar {
  background-color: $white !important;
  border-bottom: 1px solid $extra-light-grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem;

  @include for-tablet {
    flex-direction: column;
  }
  @include for-desktop {
    flex-direction: row;
  }

  .navbar__app-logo-title-container {
    display: flex;
    //background-color: gray;
    flex-direction: row;
    align-items: center;

    .navbar__app-logo {
      width: 70px;
      height: 70px;
      max-width: 70px;
      max-height: 70px;
    }

    .navbar__app-title-container {
      padding: 0 0.8rem 0 0.8rem;
      .navbar__app-title {
        color: $dark-grey;
        font-size: 2.25rem;
        font-weight: 900;
        margin: 0px 0px;
      }

      .navbar__app-subtitle {
        color: $dark-grey;
        font-size: 1rem;
        font-weight: 300;
        margin: 0px 0px;
      }
    }
  }

  // Propiedad cuando se encuentra en la página web
  .active{
    color: $purple-main !important;
    font-weight: bold;
  }
}


.topnav {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.topnav a {
  float: left;
  display: block;
  text-align: center;
  padding: 0.6rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  color: $light-grey;
}

.topnav .icon {
  display: none;
  font-size: 1.2rem;
  cursor: pointer;
}

/* Ocultar menú para tamaños teléfonos y mostrar icono de opciones*/
@include for-phone{
  .topnav a{display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }

  .topnav.responsive {
    position: relative;
    flex-direction: column;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: center;
  }
}

