// Estilos para el footer de la web

.footer__container {
  width: 100%;
  background-color: $purple-main;
  padding: 1rem 1.5rem;
  border-top: 2px solid #ccc;
  box-shadow: 0 -3px 10px 3px rgba(0, 0, 0, 0.2);

  .footer__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  .footer__items_container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @include for-desktop{
        flex-direction: row;
    }

    @include for-tablet{
        flex-direction: row;
        justify-content: space-between;
    }

  }

  // Cada uno de los bloques donde estarán los items del footer
  .footer_block{
    @include for-phone{
      min-width: 100%;
    }  
  }

  .footer__item_content {
    color: $white;
    h5 {
      font-size: 1rem;
      margin: 0;
      padding: 0;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 0.8rem;
    }
    a{
      text-decoration: none;
      color: inherit;
      &:hover{
        color: darken($color: $white, $amount: 25% );
      }
    }
  }

  // Alineación del texto
  .left {
    text-align: left;
    justify-content: flex-start;
  }

  .center{
    text-align: center;
    justify-content: center;
  }

  .right {
    text-align: right;
    justify-content: flex-end;
  }

  // Empujar los items a derecha o izquierda (icono + Texto   |  Texto + icono )
  .fistElementOrder {
    order: 1;
  }
  .secondElementOrder {
    order: 2;
  }

  i {
    color: $white;
    min-width: 45px;
    text-align: center;
    margin: 0 0.56rem;
  }
}
